import { Role, Maybe } from "./../generated/graphql";
import { GetterTree } from "vuex";
import { RootState } from "./state";
export type Getters = {
  isAuthenticated(state: RootState): boolean;
  // getAvatar(state: RootState): string
  getUserRoles(state: RootState): Maybe<Role>[];
  getWorkspaceRoles(state: RootState): Maybe<Role>[];
};
export const getters: GetterTree<RootState, RootState> & Getters = {
  isAuthenticated: state => state.authToken !== null,
  // getAvatar: state => state.loggedUser.avatar,
  getUserRoles: state => state.roles.organization.roles,
  getWorkspaceRoles: state => state.roles.workspace.roles
};
