import { OrganizationMessage, User } from "./../generated/graphql";
import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { RootState } from "./state";
import { RoleQuery } from "./actions";
import { resetStore } from ".";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MutationBase = { [key: string]: (...args: any) => any };

export type Mutations<S = RootState> = {
  [MutationTypes.RESET_STATE](): void;
  [MutationTypes.SET_LOADING](state: S, payload: boolean): void;
  [MutationTypes.SET_TOKEN](state: S, payload: string): void;
  [MutationTypes.SET_ORGANIZATION_ID](state: S, payload: string): void;
  [MutationTypes.SET_LOGGED_USER](state: S, payload: User): void;
  [MutationTypes.SET_ROLES](state: S, payload: RoleQuery): void;
  [MutationTypes.LOGOUT_USER](state: S): void;
  [MutationTypes.SET_BANNER_TEXT](
    state: S,
    bannerText: OrganizationMessage
  ): void;
};
export const mutations: MutationTree<RootState> & Mutations = {
  [MutationTypes.RESET_STATE]() {
    resetStore();
  },
  [MutationTypes.SET_LOADING](state, payload: boolean) {
    state.isLoading = payload;
  },
  [MutationTypes.SET_TOKEN](state, payload) {
    state.authToken = payload;
  },
  [MutationTypes.SET_ORGANIZATION_ID](state, payload) {
    state.organizationId = payload;
  },
  [MutationTypes.SET_LOGGED_USER](state, payload) {
    state.loggedUser = payload;
  },
  [MutationTypes.SET_ROLES](state, { workspace, organization }) {
    state.roles = {
      workspace: { roles: [...workspace.roles] },
      organization: { roles: [...organization.roles] }
    };
  },
  [MutationTypes.LOGOUT_USER](state) {
    state.authToken = "";
    state.refreshToken = "";
    state.loggedUser = null;
  },
  [MutationTypes.SET_BANNER_TEXT](state, text) {
    state.bannerText = text;
  }
};
