import { UPDATE_CURRENT_USER } from "./../graphql/mutations";
import {
  GET_BANNER_TEXT,
  GET_LOGGED_USER,
  GET_ROLES
} from "./../graphql/queries";
import apolloWrapper from "@/http";
import {
  OrganizationMessage,
  RoleController,
  User
} from "./../generated/graphql";
import { RootState } from "./state";
import { ActionTree, ActionContext } from "vuex";
import { Mutations, MutationBase } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import {
  cleanCookiesAndLocalStorage,
  getAuthToken,
  redirectToAuthPage,
  refreshToken
} from "@/auth";
import { apolloClient } from "@/vue-apollo";

export type AugmentedActionContext<S, V extends MutationBase> = {
  commit<K extends keyof V>(
    key: K,
    payload?: Parameters<V[K]>[1]
  ): ReturnType<V[K]>;
} & Omit<ActionContext<S, RootState>, "commit">;

type RootActionContext = AugmentedActionContext<RootState, Mutations>;
export interface Actions {
  [ActionTypes.RESET_STORE]({ commit }: RootActionContext): Promise<void>;
  [ActionTypes.SETUP_ORGANIZATION](
    { commit }: RootActionContext,
    payload: string
  ): Promise<string>;
  [ActionTypes.SETUP_LOGGED_USER]({
    commit
  }: RootActionContext): Promise<string | null>;
  [ActionTypes.GET_ROLES]({ commit }: RootActionContext): Promise<RoleQuery>;
  [ActionTypes.LOGOUT_USER]({ commit }: RootActionContext): Promise<boolean>;
  [ActionTypes.REFRESH_SESSION]({
    commit
  }: RootActionContext): Promise<boolean>;
  [ActionTypes.PATCH_LOGGED_USER](
    { commit }: RootActionContext,
    user: User
  ): Promise<User | null>;
  [ActionTypes.GET_BANNER_TEXT]({
    commit
  }: RootActionContext): Promise<OrganizationMessage | null>;
}

export type RoleQuery = {
  workspace: RoleController;
  organization: RoleController;
};

export const actions: ActionTree<RootState, RootState> & Actions = {
  async [ActionTypes.RESET_STORE]({ commit }) {
    await commit(MutationTypes.RESET_STATE);
    await apolloClient.cache.reset();
  },
  async [ActionTypes.SETUP_ORGANIZATION]({ commit, dispatch }, orgId) {
    await commit(MutationTypes.SET_ORGANIZATION_ID, orgId);
    dispatch(ActionTypes.FETCH_ORGANIZATION);
    dispatch(ActionTypes.FETCH_ORGANIZATION_SUMMARIES);

    dispatch(ActionTypes.GET_BANNER_TEXT);
    dispatch(ActionTypes.GET_ROLES);
    return orgId;
  },
  async [ActionTypes.SETUP_LOGGED_USER]({ commit }) {
    // If routing hook did not redirect, then we can assume an auth token is present in session
    const token = getAuthToken();
    if (token) {
      commit(MutationTypes.SET_TOKEN, token);
      const { data } = await apolloWrapper.query({
        query: GET_LOGGED_USER
      });
      const loggedUser = data.userContext.user;
      if (loggedUser) {
        commit(MutationTypes.SET_LOGGED_USER, loggedUser);
      }
      return token;
    } else {
      return null;
    }
  },
  async [ActionTypes.GET_ROLES]({ commit }) {
    const { data } = await apolloWrapper.query<RoleQuery>({
      query: GET_ROLES
    });
    commit(MutationTypes.SET_ROLES, data);
    return data;
  },
  async [ActionTypes.LOGOUT_USER]({ commit, dispatch }) {
    cleanCookiesAndLocalStorage();
    dispatch(ActionTypes.RESET_STORE);
    commit(MutationTypes.LOGOUT_USER);
    redirectToAuthPage();
    return true;
  },
  async [ActionTypes.REFRESH_SESSION]({ dispatch, state }) {
    if (state.refreshToken === null) {
      dispatch(ActionTypes.LOGOUT_USER);
      return false;
    }

    const response = await refreshToken();
    if (response === null) {
      dispatch(ActionTypes.LOGOUT_USER);
      return false;
    } else if (!response.status || response.status === 401) {
      dispatch(ActionTypes.LOGOUT_USER);
    }
    return true;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async [ActionTypes.PATCH_LOGGED_USER]({ commit }, { id, ...user }: User) {
    const { data } = await apolloWrapper.mutate({
      mutation: UPDATE_CURRENT_USER,
      variables: { input: user }
    });
    const changedUser = data?.userContext?.updateCurrentUser.user || null;
    if (changedUser) {
      commit(MutationTypes.SET_LOGGED_USER, changedUser);
    }
    return changedUser;
  },
  async [ActionTypes.GET_BANNER_TEXT]({ commit }) {
    const { data } = await apolloWrapper.query({
      query: GET_BANNER_TEXT
    });
    const bannerText = data.organizationContext.messages;
    if (bannerText && bannerText.length > 0) {
      commit(
        MutationTypes.SET_BANNER_TEXT,
        bannerText[0] as OrganizationMessage
      );
    }
    return bannerText ? bannerText[0] : null;
  }
};
