export const successToast = (that: Vue, msg: string) => {
  that.$bvToast.toast(msg, {
    toaster: "b-toaster-top-right",
    variant: "success",
    bodyClass: "bg-success text-white",
    noCloseButton: true,
    appendToast: true
  });
};

export const errorToast = (that: Vue, msg: string) => {
  that.$bvToast.toast(msg, {
    toaster: "b-toaster-top-right",
    variant: "danger",
    bodyClass: "bg-danger text-white",
    noCloseButton: true,
    appendToast: true
  });
};
