import { ActionTypes } from "./../store/action-types";
import Vue, { CreateElement } from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store/index";
import Layout from "@/components/layout/Layout.vue";
const DashboardPage = () => import("@/views/organization/DashboardPage.vue");
const InformationsPage = () =>
  import("@/views/organization/InformationsPage.vue");
const OrganizationSelectionPage = () =>
  import("@/views/organization/OrganizationSelectionPage.vue");
const BillingPage = () => import("@/views/billing/BillingPage.vue");
const PaymentMethodPage = () => import("@/views/billing/PaymentMethodPage.vue");
const InvoicesPage = () => import("@/views/billing/InvoicesPage.vue");
const UsersPage = () => import("@/views/users/UsersPage.vue");
const EditProfilePage = () => import("@/views/users/EditProfilePage.vue");
const AllWorkspacePage = () =>
  import("@/views/workspaces/AllWorkspacePage.vue");
const NewWorkspacePage = () =>
  import("@/views/workspaces/NewWorkspacePage.vue");
const WorkspaceAccountPage = () =>
  import("@/views/workspaces/WorkspaceAccountPage.vue");
const WorkspacePlanPage = () =>
  import("@/views/workspaces/WorkspacePlanPage.vue");
const WorkspaceUsagePage = () =>
  import("@/views/workspaces/WorkspaceUsagePage.vue");
const WorkspaceUsersPage = () =>
  import("@/views/workspaces/WorkspaceUsersPage.vue");
const NotFoundPage = () => import("@/views/NotFoundPage.vue");

Vue.use(VueRouter);

export type StentRouteConfig = RouteConfig & {
  featherIcon?: string;
  meta?: unknown & {
    requiresAuth?: boolean;
    isInNav?: boolean;
  };
  children?: StentRouteConfig[];
};

// Empty component used for parent route to directly render child component
const parentRoute = {
  component: {
    render: (h: CreateElement) => {
      return h("router-view");
    }
  },
  meta: { isInNav: true }
};

export enum NavType {
  NONE,
  PRIMARY,
  WORKSPACE
}

export enum RouteName {
  SWITCH_ORG = "Switch organization",
  ORGANIZATION = "Organization",
  INFORMATIONS = "Informations",
  DASHBOARD = "Dashboard",
  BILLING = "Billing",
  ACCOUNT = "Account",
  PAYMENT_OPTIONS = "Payment Options",
  INVOICES = "Invoices",
  USERS = "Users",
  WORKSPACE = "Workspace",
  WORKSPACES = "Workspaces",
  NEW_WORKSPACE = "New Workspace",
  WORKSPACE_ACCOUNT = "Workspace Account",
  WORKSPACE_PLAN = "Plan",
  WORKSPACE_USAGE = "Usage",
  WORKSPACE_USERS = "Workspace Users",
  EDIT_PROFILE = "Edit Profile"
}

export const routes: Array<StentRouteConfig> = [
  {
    path: "/",
    name: RouteName.SWITCH_ORG,
    component: OrganizationSelectionPage,
    props: true,
    meta: {
      requiresAuth: true,
      navType: NavType.NONE
    }
  },
  {
    name: RouteName.ORGANIZATION,
    path: "/:orgId",
    redirect: { name: RouteName.DASHBOARD },
    component: Layout,
    props: route => {
      route.params.orgId;
    },
    children: [
      {
        path: "dashboard",
        name: RouteName.DASHBOARD,
        component: DashboardPage,
        featherIcon: "fe-home",
        meta: {
          requiresAuth: true,
          navType: NavType.PRIMARY
        },
        props: route => {
          route.params.orgId;
        }
      },
      {
        path: "info",
        name: RouteName.INFORMATIONS,
        component: InformationsPage,
        featherIcon: "fe-at-sign",
        meta: {
          requiresAuth: true,
          navType: NavType.PRIMARY
        },
        props: route => {
          route.params.orgId;
        }
      },
      {
        name: RouteName.BILLING,
        path: "billing",
        ...parentRoute,
        featherIcon: "fe-file-text",
        children: [
          {
            path: "account",
            name: RouteName.ACCOUNT,
            component: BillingPage,
            meta: {
              requiresAuth: true,
              navType: NavType.PRIMARY
            }
          },
          {
            path: "payment-options",
            name: RouteName.PAYMENT_OPTIONS,
            component: PaymentMethodPage,
            meta: {
              requiresAuth: true,
              navType: NavType.PRIMARY
            }
          },
          {
            path: "invoices",
            name: RouteName.INVOICES,
            component: InvoicesPage,
            meta: {
              requiresAuth: true,
              navType: NavType.PRIMARY
            }
          }
        ]
      },
      {
        path: "users",
        name: RouteName.USERS,
        component: UsersPage,
        featherIcon: "fe-users",
        meta: {
          requiresAuth: true,
          navType: NavType.PRIMARY
        }
      },
      {
        name: RouteName.WORKSPACE,
        path: "workspace",
        ...parentRoute,
        featherIcon: "fe-share-2",
        children: [
          {
            path: "all",
            name: RouteName.WORKSPACES,
            component: AllWorkspacePage,
            meta: {
              requiresAuth: true,
              navType: NavType.PRIMARY
            }
          },
          {
            path: "new",
            name: RouteName.NEW_WORKSPACE,
            component: NewWorkspacePage,
            meta: {
              requiresAuth: true,
              navType: NavType.NONE
            }
          },
          {
            path: ":id/account",
            name: RouteName.WORKSPACE_ACCOUNT,
            component: WorkspaceAccountPage,
            meta: {
              requiresAuth: true,

              navType: NavType.WORKSPACE
            }
          },
          {
            path: ":id/plan",
            name: RouteName.WORKSPACE_PLAN,
            component: WorkspacePlanPage,
            meta: {
              requiresAuth: true,

              navType: NavType.WORKSPACE
            }
          },
          {
            path: ":id/usage",
            name: RouteName.WORKSPACE_USAGE,
            component: WorkspaceUsagePage,
            meta: {
              requiresAuth: true,

              navType: NavType.WORKSPACE
            }
          },
          {
            path: ":id/users",
            name: RouteName.WORKSPACE_USERS,
            component: WorkspaceUsersPage,
            meta: {
              requiresAuth: true,

              navType: NavType.WORKSPACE
            }
          }
        ]
      },
      {
        path: "edit-profile",
        name: RouteName.EDIT_PROFILE,
        component: EditProfilePage,
        meta: {
          requiresAuth: true,

          navType: NavType.NONE
        }
      }
    ]
  },
  {
    path: "*",
    component: NotFoundPage
  }
];

const router = new VueRouter({
  linkExactActiveClass: "active",
  routes: routes,
  mode: "history"
});

router.beforeEach(async (to, from, next) => {
  const isUserLoggedIn = store.getters.isAuthenticated;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isUserLoggedIn) {
      // Redirect to login app
      const refreshed = await store.dispatch(ActionTypes.REFRESH_SESSION);
      if (refreshed) {
        next();
      }
    } else if (isUserLoggedIn && store.state.loggedUser === null) {
      store.dispatch(ActionTypes.SETUP_LOGGED_USER);
      window.scrollTo({ top: 0 });
      next();
    } else {
      // Allow routing
      window.scrollTo({ top: 0 });
      next();
    }
  } else {
    next();
  }
});

export default router;
