import {
  CREATE_WORKSPACE,
  UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES,
  MARK_ORGANIZATION_WORKSPACE_AS_FAVORITE,
  REMOVE_ORGANIZATION_WORKSPACE_FROM_FAVORITE,
  INVITE_ORGANIZATION_WORKSPACE_USER,
  REMOVE_ORGANIZATION_WORKSPACE_USER,
  CHANGE_PLAN_ORGANIZATION_WORKSPACE,
  ACTIVATE_WORKSPACE
} from "./../../graphql/workspace/mutations";
import {
  GET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE,
  GET_ELIGIBLE_WORKSPACE_PLANS,
  GET_POTENTIAL_WORKSPACE_USERS,
  GET_WORKSPACE_SUBSCRIPTION,
  GET_WORKSPACE_SUMMARIES
} from "./../../graphql/workspace/queries";
import { MutationTree } from "vuex";
import { ActionTree } from "vuex";
import { RootState } from "./../state";
import { GetterTree } from "vuex";
import {
  WorkspaceFilter,
  WorkspaceUserFilter,
  MonthYear,
  WorkspaceSummary,
  toWorkspaceSummary,
  FilteredWorkspace,
  toFilteredWorkspace
} from "./../../models/workspace";
import { ActionTypes } from "./../action-types";
import { AugmentedActionContext } from "./../actions";
import {
  OrganizationWorkspaceUser,
  Role,
  OrganizationWorkspace,
  CreditsUsage,
  Subscription,
  Status,
  PastCreditsUsage,
  UsageHistory,
  UpdateOrganizationWorkspaceInput,
  InviteUserInput,
  SubscriptionSettings,
  OrganizationMutationContextUpdateOrganizationWorkspaceArgs,
  OrganizationMutationContextCreateOrganizationWorkspaceArgs,
  OrganizationMutationContextUpdateOrganizationWorkspaceUserRolesArgs,
  Plan
} from "./../../generated/graphql";
import apolloWrapper from "@/http";
import {
  GET_WORKSPACES,
  GET_WORKSPACE,
  GET_WORKSPACE_USERS,
  GET_WORKSPACE_USAGE
} from "@/graphql/workspace/queries";
import { compareDesc } from "date-fns";
import {
  UPDATE_WORKSPACE,
  STOP_WORKSPACE,
  ARCHIVE_WORKSPACE
} from "@/graphql/workspace/mutations";
import router, { RouteName } from "@/router";
import { Page, Paginated } from "@/types/types";
import { EnhancedPlan, toPlan } from "@/models/organization";

/**********************
 * Types & Interfaces *
 **********************/
type WorkspaceUsage = Pick<CreditsUsage, "current" | "history">;

export interface WorkspaceState {
  workspaceSummaries: WorkspaceSummary[]; // used for WorkspaceNavbar and NavLinkList
  filteredWorkspaces: {
    list: { [key: string]: FilteredWorkspace } | null;
  } & Page; // used for AllWorkspacesPage
  currentWorkspace: OrganizationWorkspace | null; // Used for every workspace specific pages
  currentWorkspaceSubscription: Subscription | null; // Used for WorkspacePlanPage and WorkspaceUsagePage
  currentWorkspaceUsers: Paginated<OrganizationWorkspaceUser>;
  currentWorkspaceUsage: WorkspaceUsage;
  currentWorkspacePastCreditUsage: Record<string, PastCreditsUsage>;
  potentialWorkspaceUsers: OrganizationWorkspaceUser[];
}

export type WorkspaceGetters = {
  getFilteredWorkspaces(state: WorkspaceState): FilteredWorkspace[] | null;
  getEditableWorkspaceSummaries(state: WorkspaceState): WorkspaceSummary[];
  getFavoriteWorkspaceSummaries(state: WorkspaceState): WorkspaceSummary[];
  getPastCreditUsageByMonthYear: (
    state: WorkspaceState
  ) => (monthYear: MonthYear) => PastCreditsUsage | null;
  isCurrencyEditable(state: WorkspaceState): boolean;
};

type WorkspaceActionContext = AugmentedActionContext<
  WorkspaceState,
  WorkspaceMutations
>;

interface WorkspaceActions {
  [ActionTypes.FETCH_WORKSPACE_SUMMARIES]({
    commit
  }: WorkspaceActionContext): Promise<WorkspaceSummary[]>;
  [ActionTypes.FILTER_WORKSPACES](
    { commit }: WorkspaceActionContext,
    payload: { filter: WorkspaceFilter; loadMore: boolean }
  ): Promise<FilteredWorkspace[]>;
  [ActionTypes.FETCH_CURRENT_WORKSPACE_ELIGIBLE_PLANS](
    { commit }: WorkspaceActionContext,
    id: string
  ): Promise<string[]>;
  [ActionTypes.RESET_WORKSPACES]({
    commit
  }: WorkspaceActionContext): Promise<void>;
  [ActionTypes.FETCH_CURRENT_WORKSPACE](
    { commit }: WorkspaceActionContext,
    id: string
  ): Promise<OrganizationWorkspace | null>;
  [ActionTypes.FETCH_CURRENT_WORKSPACE_SUBSCRIPTION](
    { commit }: WorkspaceActionContext,
    id: string
  ): Promise<Subscription | null>;
  [ActionTypes.UPDATE_CURRENT_WORKSPACE](
    { commit }: WorkspaceActionContext,
    input: UpdateOrganizationWorkspaceInput
  ): Promise<OrganizationWorkspace | null>;
  [ActionTypes.UPDATE_CURRENT_WORKSPACE_PLAN](
    { commit }: WorkspaceActionContext,
    payload: SubscriptionSettings & { priceId: string }
  ): Promise<Subscription>;
  [ActionTypes.FETCH_CURRENT_WORKSPACE_USAGE](
    { commit }: WorkspaceActionContext,
    id: string
  ): Promise<WorkspaceUsage | null>;
  [ActionTypes.FETCH_CURRENT_WORKSPACE_PAST_CREDIT_USAGE](
    { commit }: WorkspaceActionContext,
    payload: { id: string; monthYear: MonthYear }
  ): Promise<PastCreditsUsage | null>;
  [ActionTypes.FETCH_CURRENT_WORKSPACE_USERS](
    { commit }: WorkspaceActionContext,
    payload: { filter: WorkspaceUserFilter; id: string; loadMore: boolean }
  ): Promise<OrganizationWorkspaceUser[]>;
  [ActionTypes.RESET_CURRENT_WORKSPACE]({
    commit
  }: WorkspaceActionContext): Promise<void>;
  [ActionTypes.CREATE_WORKSPACE](
    { commit }: WorkspaceActionContext,
    payload: OrganizationMutationContextCreateOrganizationWorkspaceArgs
  ): Promise<OrganizationWorkspace>;
  [ActionTypes.ACTIVATE_WORKSPACE](
    { commit }: WorkspaceActionContext,
    payload: { wsId: string; priceId: string }
  ): Promise<boolean>;
  [ActionTypes.STOP_WORKSPACE](
    { commit }: WorkspaceActionContext,
    id: string
  ): Promise<boolean>;
  [ActionTypes.ARCHIVE_WORKSPACE](
    { commit }: WorkspaceActionContext,
    id: string
  ): Promise<boolean>;
  [ActionTypes.TOGGLE_WORKSPACE_VISIBILITY](
    { commit }: WorkspaceActionContext,
    payload: { id: string; isFavorite: boolean }
  ): Promise<boolean>;

  [ActionTypes.UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES](
    { commit }: WorkspaceActionContext,
    payload: OrganizationMutationContextUpdateOrganizationWorkspaceUserRolesArgs
  ): Promise<void>;
  [ActionTypes.INVITE_WORKSPACE_USER](
    { commit }: WorkspaceActionContext,
    userInvite: InviteUserInput
  ): Promise<OrganizationWorkspaceUser | null>;
  [ActionTypes.REINVITE_WORKSPACE_USER](
    { commit }: WorkspaceActionContext,
    userInvite: InviteUserInput
  ): Promise<OrganizationWorkspaceUser | null>;
  [ActionTypes.DELETE_WORKSPACE_USER](
    { commit }: WorkspaceActionContext,
    email: string
  ): Promise<boolean>;
  [ActionTypes.FETCH_POTENTIAL_WORKSPACE_USERS](
    { commit }: WorkspaceActionContext,
    name: string
  ): Promise<OrganizationWorkspaceUser[]>;
  [ActionTypes.RESET_POTENTIAL_WORKSPACE_USERS]({
    commit
  }: WorkspaceActionContext): Promise<void>;
}

enum MutationTypes {
  SET_WORKSPACE_SUMMARIES = "SET_WORKSPACE_SUMMARIES",
  ADD_WORKSPACE = "ADD_WORKSPACE",
  SET_FILTERED_WORKSPACES = "SET_FILTERED_WORKSPACES",
  PATCH_WORKSPACE = "PATCH_WORKSPACE",
  SET_CURRENT_WORKSPACE = "SET_CURRENT_WORKSPACE",
  SET_CURRENT_WORKSPACE_SUBSCRIPTION = "SET_CURRENT_WORKSPACE_SUBSCRIPTION",
  SET_CURRENT_WORKSPACE_USAGE = "SET_CURRENT_WORKSPACE_USAGE",
  SET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE = "SET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE",
  SET_CURRENT_WORKSPACE_USERS = "SET_CURRENT_WORKSPACE_USERS",
  SET_WORKSPACE_SUBSCRIPTION = "SET_WORKSPACE_SUBSCRIPTION",
  SET_WORKSPACE_PLAN = "SET_WORKSPACE_PLAN",
  SET_WORKSPACE_STATUS = "SET_WORKSPACE_STATUS",
  UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES = "UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES",
  TOGGLE_WORKSPACE_VISIBILITY = "TOGGLE_WORKSPACE_VISIBILITY",
  ADD_CURRENT_WORKSPACE_USER = "ADD_CURRENT_WORKSPACE_USER",
  UPDATE_CURRENT_WORKSPACE_USER = "UPDATE_CURRENT_WORKSPACE_USER",
  DELETE_CURRENT_WORKSPACE_USER = "DELETE_CURRENT_WORKSPACE_USER",
  SET_POTENTIAL_WORKSPACE_USERS = "SET_POTENTIAL_WORKSPACE_USERS"
}

type WorkspaceMutations = {
  [MutationTypes.ADD_WORKSPACE](
    state: WorkspaceState,
    workspace: OrganizationWorkspace
  ): void;
  [MutationTypes.SET_WORKSPACE_SUMMARIES](
    state: WorkspaceState,
    workspace: WorkspaceSummary[]
  ): void;
  [MutationTypes.SET_FILTERED_WORKSPACES](
    state: WorkspaceState,
    workspace: { workspaces: FilteredWorkspace[]; page: Page } | null
  ): void;
  [MutationTypes.PATCH_WORKSPACE](
    state: WorkspaceState,
    workspace: OrganizationWorkspace
  ): void;
  [MutationTypes.SET_CURRENT_WORKSPACE](
    state: WorkspaceState,
    workspace: OrganizationWorkspace | null
  ): void;
  [MutationTypes.SET_CURRENT_WORKSPACE_SUBSCRIPTION](
    state: WorkspaceState,
    subscription: Subscription | null
  ): void;
  [MutationTypes.SET_WORKSPACE_STATUS](
    state: WorkspaceState,
    payload: { id: string; status: Status }
  ): void;
  [MutationTypes.SET_WORKSPACE_PLAN](
    state: WorkspaceState,
    payload: { id: string; plan: Plan }
  ): void;
  [MutationTypes.SET_WORKSPACE_SUBSCRIPTION](
    state: WorkspaceState,
    payload: { id: string; subscription: Subscription }
  ): void;
  [MutationTypes.SET_CURRENT_WORKSPACE_USAGE](
    state: WorkspaceState,
    payload: WorkspaceUsage
  ): void;
  [MutationTypes.SET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE](
    state: WorkspaceState,
    payload: PastCreditsUsage | null
  ): void;
  [MutationTypes.SET_CURRENT_WORKSPACE_USERS](
    state: WorkspaceState,
    payload: { users: OrganizationWorkspaceUser[]; page: Page }
  ): void;
  [MutationTypes.UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES](
    state: WorkspaceState,
    payload: { id: string; roles: Role[] }
  ): void;
  [MutationTypes.TOGGLE_WORKSPACE_VISIBILITY](
    state: WorkspaceState,
    payload: string
  ): void;
  [MutationTypes.ADD_CURRENT_WORKSPACE_USER](
    state: WorkspaceState,
    payload: OrganizationWorkspaceUser
  ): void;
  [MutationTypes.UPDATE_CURRENT_WORKSPACE_USER](
    state: WorkspaceState,
    payload: OrganizationWorkspaceUser
  ): void;
  [MutationTypes.DELETE_CURRENT_WORKSPACE_USER](
    state: WorkspaceState,
    payload: string
  ): void;
  [MutationTypes.SET_POTENTIAL_WORKSPACE_USERS](
    state: WorkspaceState,
    payload: OrganizationWorkspaceUser[]
  ): void;
};

/**********************
 *       State        *
 **********************/
export const state: WorkspaceState = {
  workspaceSummaries: [],
  filteredWorkspaces: {
    list: null,
    hasNextPage: false,
    endCursor: ""
  },
  currentWorkspace: null,
  currentWorkspaceSubscription: null,
  currentWorkspaceUsers: {
    list: null,
    hasNextPage: false,
    endCursor: ""
  },
  currentWorkspaceUsage: {
    current: {
      periodEnd: null,
      periodStart: null,
      cumulated: [],
      daily: [],
      forecast: [],
      trend: [],
      used: []
    },
    history: []
  },
  currentWorkspacePastCreditUsage: {},
  potentialWorkspaceUsers: []
};

/**********************
 *      Getters       *
 **********************/
const getters: GetterTree<WorkspaceState, RootState> & WorkspaceGetters = {
  getFilteredWorkspaces: state =>
    state.filteredWorkspaces.list
      ? Object.values(state.filteredWorkspaces.list).sort((a, b) =>
          a.company.name.localeCompare(b.company.name)
        )
      : null,
  getActiveWorkspace: state =>
    state.filteredWorkspaces.list
      ? Object.values(state.filteredWorkspaces.list)
          .sort((a, b) => a.status.localeCompare(b.status))
          .filter(w => w.status === Status.Active)
      : null,
  getFavoriteWorkspaceSummaries: state =>
    state.workspaceSummaries.filter(
      w => w.isFavorite && w.status !== Status.Archive
    ),
  getEditableWorkspaceSummaries: state =>
    state.workspaceSummaries.filter(w => w.status !== Status.Archive),
  getPastCreditUsageByMonthYear: state => ({ month, year }) =>
    state.currentWorkspacePastCreditUsage[`${month}-${year}`]
      ? state.currentWorkspacePastCreditUsage[`${month}-${year}`]
      : null,
  isCurrencyEditable: state =>
    state.workspaceSummaries.every(ws => !ws.subscription.id)
};

/**********************
 *      Actions       *
 **********************/
const actions: ActionTree<WorkspaceState, RootState> & WorkspaceActions = {
  async [ActionTypes.FETCH_WORKSPACE_SUMMARIES]({ commit }) {
    const { data } = await apolloWrapper.query({
      query: GET_WORKSPACE_SUMMARIES,
      fetchPolicy: "network-only"
    });
    const workspaces = data.organizationContext.workspaces.edges.map(w =>
      toWorkspaceSummary(w.node)
    );
    commit(MutationTypes.SET_WORKSPACE_SUMMARIES, workspaces);
    return workspaces;
  },
  async [ActionTypes.FILTER_WORKSPACES](
    { state, commit, getters },
    { filter, loadMore = false }
  ) {
    const cursor = loadMore ? state.filteredWorkspaces.endCursor : "";

    const variables = filter
      ? { ...filter, cursor }
      : { cursor, name: "", price: null, status: "ACTIVE" };
    const { data } = await apolloWrapper.query(
      {
        query: GET_WORKSPACES,
        variables,
        fetchPolicy: "network-only",
        errorPolicy: "ignore"
      },
      false
    );
    const workspaces = data.organizationContext.workspaces.edges.map(w =>
      toFilteredWorkspace(w.node)
    );

    const page = {
      hasNextPage: data.organizationContext.workspaces.pageInfo.hasNextPage,
      endCursor: data.organizationContext.workspaces.pageInfo.endCursor || ""
    };
    commit(MutationTypes.SET_FILTERED_WORKSPACES, {
      workspaces: loadMore
        ? [...getters.getFilteredWorkspaces, ...workspaces]
        : workspaces,
      page
    });
    return workspaces;
  },

  async [ActionTypes.FETCH_CURRENT_WORKSPACE_ELIGIBLE_PLANS](_, id) {
    const { data } = await apolloWrapper.query({
      query: GET_ELIGIBLE_WORKSPACE_PLANS,
      variables: { wsKey: id },
      fetchPolicy: "network-only",
      errorPolicy: "ignore"
    });

    const plans = data.organizationContext.workspaceById.plans.map(
      p => p?.id
    ) as string[];

    return plans;
  },

  async [ActionTypes.RESET_WORKSPACES]({ commit }) {
    commit(MutationTypes.SET_FILTERED_WORKSPACES, null);
  },

  async [ActionTypes.FETCH_CURRENT_WORKSPACE]({ commit }, id) {
    const { data } = await apolloWrapper.query({
      query: GET_WORKSPACE,
      variables: { id },
      fetchPolicy: "network-only"
    });
    const workspace = data.organizationContext.workspaceById;
    commit(MutationTypes.SET_CURRENT_WORKSPACE, workspace);
    return workspace;
  },

  async [ActionTypes.FETCH_CURRENT_WORKSPACE_SUBSCRIPTION]({ commit }, id) {
    const { data } = await apolloWrapper.query({
      query: GET_WORKSPACE_SUBSCRIPTION,
      variables: { id },
      fetchPolicy: "network-only"
    });
    const subscription = data.organizationContext.workspaceById.subscription;
    if (subscription) {
      commit(MutationTypes.SET_CURRENT_WORKSPACE_SUBSCRIPTION, subscription);
    }
    return subscription || null;
  },

  async [ActionTypes.UPDATE_CURRENT_WORKSPACE]({ commit, state }, input) {
    if (state.currentWorkspace) {
      const variables = {
        id: state.currentWorkspace.id,
        input
      } as OrganizationMutationContextUpdateOrganizationWorkspaceArgs;
      const { data, errors } = await apolloWrapper.mutate({
        mutation: UPDATE_WORKSPACE,
        variables
      });
      const patchedWorkspace = data
        ? data.organizationContext.updateOrganizationWorkspace
            .organizationWorkspace
        : null;
      if (patchedWorkspace) {
        commit(MutationTypes.PATCH_WORKSPACE, patchedWorkspace);
        router.push({
          name: RouteName.WORKSPACES,
          params: {
            orgId: router.currentRoute.params.orgId
          }
        });
      } else {
        throw new Error(`Update workspace error: ${errors}`);
      }
      return patchedWorkspace;
    }
    return null;
  },

  async [ActionTypes.UPDATE_CURRENT_WORKSPACE_PLAN](
    { commit, state },
    { priceId, ...input }
  ) {
    if (state.currentWorkspace) {
      const { data } = await apolloWrapper.mutate({
        mutation: CHANGE_PLAN_ORGANIZATION_WORKSPACE,
        variables: { id: state.currentWorkspace.id, input, priceId }
      });
      const subscription = data?.organizationContext
        ?.changePlanOrganizationWorkspace.subscription as Subscription;
      commit(MutationTypes.SET_WORKSPACE_SUBSCRIPTION, {
        id: state.currentWorkspace.id,
        subscription
      });
      return subscription;
    } else {
      throw new Error(
        "Cannot update current workspace plan: No current workspace has been selected"
      );
    }
  },

  async [ActionTypes.FETCH_CURRENT_WORKSPACE_USAGE]({ commit }, id) {
    const { data } = await apolloWrapper.query({
      query: GET_WORKSPACE_USAGE,
      variables: { id }
    });
    const credit = data.organizationContext.workspaceById
      .usage as WorkspaceUsage;
    commit(MutationTypes.SET_CURRENT_WORKSPACE_USAGE, credit);
    return credit;
  },

  async [ActionTypes.FETCH_CURRENT_WORKSPACE_PAST_CREDIT_USAGE](
    { commit },
    { id, monthYear }: { id: string; monthYear: MonthYear }
  ) {
    const { data } = await apolloWrapper.query({
      query: GET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE,
      variables: { id, month: monthYear.month, year: monthYear.year }
    });
    const past = data.organizationContext.workspaceById.usage.past;
    commit(MutationTypes.SET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE, past);
    return past;
  },

  async [ActionTypes.FETCH_CURRENT_WORKSPACE_USERS](
    { commit, state },
    { filter: { name, role }, id, loadMore = false }
  ) {
    const cursor = loadMore ? state.currentWorkspaceUsers.endCursor : "";

    if (id) {
      const { data } = await apolloWrapper.query(
        {
          query: GET_WORKSPACE_USERS,
          fetchPolicy: "network-only",
          variables: { wsKey: id, name, role, cursor }
        },
        false
      );
      const users = data.organizationContext.workspaceById.users.edges.map(
        n => n.node
      );
      const page = {
        hasNextPage:
          data.organizationContext.workspaceById.users.pageInfo.hasNextPage,
        endCursor:
          data.organizationContext.workspaceById.users.pageInfo.endCursor || ""
      };
      commit(MutationTypes.SET_CURRENT_WORKSPACE_USERS, {
        users: loadMore
          ? [...(state.currentWorkspaceUsers.list || []), ...users]
          : users,
        page
      });
      return users;
    }
    return [];
  },
  async [ActionTypes.RESET_CURRENT_WORKSPACE]({ commit }) {
    commit(MutationTypes.SET_CURRENT_WORKSPACE, null);
    commit(MutationTypes.SET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE, null);
    commit(MutationTypes.SET_CURRENT_WORKSPACE_SUBSCRIPTION, null);
    commit(MutationTypes.SET_CURRENT_WORKSPACE_USAGE, {
      current: null,
      history: []
    });
    commit(MutationTypes.SET_CURRENT_WORKSPACE_USERS, {
      users: [],
      page: {
        hasNextPage: false,
        endCursor: ""
      }
    });
  },

  async [ActionTypes.CREATE_WORKSPACE]({ commit }, { input, priceId }) {
    const { data, errors } = await apolloWrapper.mutate({
      mutation: CREATE_WORKSPACE,
      variables: {
        input,
        priceId
      }
    });
    const createdWorkspace = data
      ? data.organizationContext.createOrganizationWorkspace
          .organizationWorkspace
      : null;
    if (createdWorkspace) {
      commit(MutationTypes.ADD_WORKSPACE, createdWorkspace);
      router.push({
        name: RouteName.WORKSPACE_ACCOUNT,
        params: {
          orgId: router.currentRoute.params.orgId,
          id: createdWorkspace.id
        }
      });
    } else {
      throw new Error(`Create workspace error: ${errors}`);
    }
    return createdWorkspace;
  },

  async [ActionTypes.ACTIVATE_WORKSPACE](
    { commit, rootGetters },
    { wsId, priceId }
  ) {
    await apolloWrapper.mutate({
      mutation: ACTIVATE_WORKSPACE,
      variables: { id: wsId, priceId }
    });
    const plan = toPlan(
      (rootGetters.getPlans as EnhancedPlan[]).find(
        p => p.id === priceId
      ) as EnhancedPlan
    );
    commit(MutationTypes.SET_WORKSPACE_STATUS, {
      id: wsId,
      status: Status.Active
    });
    commit(MutationTypes.SET_WORKSPACE_PLAN, {
      id: wsId,
      plan
    });
    return true;
  },
  async [ActionTypes.STOP_WORKSPACE]({ commit }, id) {
    await apolloWrapper.mutate({
      mutation: STOP_WORKSPACE,
      variables: { id }
    });
    commit(MutationTypes.SET_WORKSPACE_STATUS, {
      id,
      status: Status.Stop
    });
    return true;
  },
  async [ActionTypes.ARCHIVE_WORKSPACE]({ commit }, id) {
    await apolloWrapper.mutate({
      mutation: ARCHIVE_WORKSPACE,
      variables: { id }
    });
    commit(MutationTypes.SET_WORKSPACE_STATUS, {
      id,
      status: Status.Archive
    });
    return true;
  },

  async [ActionTypes.TOGGLE_WORKSPACE_VISIBILITY](
    { commit },
    { id, isFavorite }
  ) {
    const { data } = await apolloWrapper.mutate({
      mutation: isFavorite
        ? REMOVE_ORGANIZATION_WORKSPACE_FROM_FAVORITE
        : MARK_ORGANIZATION_WORKSPACE_AS_FAVORITE,
      variables: { id }
    });
    const result = (isFavorite
      ? data?.organizationContext?.removeOrganizationWorkspaceFromFavorites
      : data?.organizationContext
          ?.markOrganizationWorkspaceAsFavorite) as boolean;
    if (result) {
      commit(MutationTypes.TOGGLE_WORKSPACE_VISIBILITY, id);
    }
    return result;
  },
  async [ActionTypes.INVITE_WORKSPACE_USER]({ commit, state }, input) {
    if (state.currentWorkspace) {
      const { data } = await apolloWrapper.mutate({
        mutation: INVITE_ORGANIZATION_WORKSPACE_USER,
        variables: { id: state.currentWorkspace.id, input }
      });
      const user =
        data?.organizationContext?.inviteOrganizationWorkspaceUser
          .organizationWorkspaceUser;
      if (user) {
        commit(MutationTypes.ADD_CURRENT_WORKSPACE_USER, user);
        return user;
      } else {
        return null;
      }
    } else {
      throw new Error(
        "Cannot invite current workspace user: No current workspace has been selected"
      );
    }
  },
  async [ActionTypes.REINVITE_WORKSPACE_USER]({ commit, state }, input) {
    if (state.currentWorkspace) {
      const { data } = await apolloWrapper.mutate({
        mutation: INVITE_ORGANIZATION_WORKSPACE_USER,
        variables: { id: state.currentWorkspace.id, input }
      });
      const user =
        data?.organizationContext?.inviteOrganizationWorkspaceUser
          .organizationWorkspaceUser;
      if (user) {
        commit(MutationTypes.ADD_CURRENT_WORKSPACE_USER, user);
        return user;
      } else {
        return null;
      }
    } else {
      throw new Error(
        "Cannot reinvite current workspace user: No current workspace has been selected"
      );
    }
  },
  async [ActionTypes.UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES](
    { commit },
    payload
  ) {
    const { data } = await apolloWrapper.mutate({
      mutation: UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES,
      variables: payload
    });

    const roles = data?.organizationContext
      ?.updateOrganizationWorkspaceUserRoles?.roles as Role[];
    commit(MutationTypes.UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES, {
      id: payload.userKey,
      roles
    });
  },
  async [ActionTypes.DELETE_WORKSPACE_USER]({ commit, state }, userKey) {
    if (state.currentWorkspace) {
      const { data } = await apolloWrapper.mutate({
        mutation: REMOVE_ORGANIZATION_WORKSPACE_USER,
        variables: { id: state.currentWorkspace.id, userKey }
      });

      commit(MutationTypes.DELETE_CURRENT_WORKSPACE_USER, userKey);
      return data?.organizationContext
        ?.removeOrganizationWorkspaceUser as boolean;
    } else {
      return false;
    }
  },

  async [ActionTypes.FETCH_POTENTIAL_WORKSPACE_USERS]({ commit, state }, name) {
    if (state.currentWorkspace) {
      let users = [] as OrganizationWorkspaceUser[];
      if (name && name.length >= 3) {
        const { data } = await apolloWrapper.query(
          {
            query: GET_POTENTIAL_WORKSPACE_USERS,
            variables: { wsKey: state.currentWorkspace.id, name }
          },
          false
        );
        users = data.organizationContext.workspaceById
          .usersByName as OrganizationWorkspaceUser[];
      }

      commit(MutationTypes.SET_POTENTIAL_WORKSPACE_USERS, users);
      return users;
    }
    return [];
  },

  async [ActionTypes.RESET_POTENTIAL_WORKSPACE_USERS]({ commit }) {
    commit(MutationTypes.SET_POTENTIAL_WORKSPACE_USERS, []);
  }
};

/**********************
 *     Mutations      *
 **********************/
const mutations: MutationTree<WorkspaceState> & WorkspaceMutations = {
  [MutationTypes.SET_WORKSPACE_SUMMARIES](state, ws) {
    state.workspaceSummaries = ws;
  },
  [MutationTypes.SET_FILTERED_WORKSPACES](state, payload) {
    if (payload) {
      const { workspaces, page } = payload;
      state.filteredWorkspaces = {
        list: workspaces.reduce((acc, val) => ({ ...acc, [val.id]: val }), {}),
        ...page
      };
    } else {
      state.filteredWorkspaces = {
        list: null,
        hasNextPage: false,
        endCursor: ""
      };
    }
  },
  [MutationTypes.ADD_WORKSPACE](state, ws) {
    state.workspaceSummaries = [
      ...state.workspaceSummaries,
      toWorkspaceSummary(ws)
    ];
    state.filteredWorkspaces = {
      ...state.filteredWorkspaces,
      list: {
        ...state.filteredWorkspaces.list,
        [ws.id]: toFilteredWorkspace(ws)
      }
    };
  },
  [MutationTypes.PATCH_WORKSPACE](state, ws) {
    state.workspaceSummaries = state.workspaceSummaries.map(w =>
      w.id === ws.id ? toWorkspaceSummary(ws) : w
    );
    state.filteredWorkspaces = {
      ...state.filteredWorkspaces,
      list: {
        ...state.filteredWorkspaces.list,
        [ws.id]: toFilteredWorkspace(ws)
      }
    };
  },
  [MutationTypes.SET_CURRENT_WORKSPACE](state, ws) {
    state.currentWorkspace = ws;
  },
  [MutationTypes.SET_CURRENT_WORKSPACE_SUBSCRIPTION](state, subscription) {
    state.currentWorkspaceSubscription = subscription;
  },
  [MutationTypes.SET_WORKSPACE_STATUS](state, { id, status }) {
    if (state.currentWorkspace && state.currentWorkspace.id === id) {
      state.currentWorkspace = { ...state.currentWorkspace, status };
    }
    if (state.filteredWorkspaces.list && state.filteredWorkspaces.list[id]) {
      state.filteredWorkspaces = {
        ...state.filteredWorkspaces,
        list: {
          ...state.filteredWorkspaces.list,
          [id]: { ...state.filteredWorkspaces.list[id], status }
        }
      };
    }
  },
  [MutationTypes.SET_WORKSPACE_SUBSCRIPTION](state, { id, subscription }) {
    if (state.currentWorkspaceSubscription?.plan.id) {
      state.currentWorkspaceSubscription = subscription;
    }
    if (state.filteredWorkspaces.list && state.filteredWorkspaces.list[id]) {
      state.filteredWorkspaces = {
        ...state.filteredWorkspaces,
        [id]: {
          ...state.filteredWorkspaces.list[id],
          subscription: {
            plan: { name: subscription.plan ? subscription.plan.name : null }
          }
        }
      };
    }
  },
  [MutationTypes.SET_WORKSPACE_PLAN](state, { id, plan }) {
    if (state.filteredWorkspaces.list && state.filteredWorkspaces.list[id]) {
      state.filteredWorkspaces = {
        ...state.filteredWorkspaces,
        [id]: {
          ...state.filteredWorkspaces.list[id],
          subscription: {
            plan
          }
        }
      };
    }
  },
  [MutationTypes.SET_CURRENT_WORKSPACE_USAGE](state, { current, history }) {
    const h = history as UsageHistory[];
    const defaultCurrent = {
      periodEnd: null,
      periodStart: null,
      cumulated: [],
      daily: [],
      forecast: [],
      trend: [],
      used: []
    };
    state.currentWorkspaceUsage = {
      current: current || defaultCurrent,
      history: h
        .map(h => ({ ...h, monthYear: new Date(h.year, h.month - 1) }))
        .sort((a, b) => compareDesc(a.monthYear, b.monthYear))
    };
  },
  [MutationTypes.SET_CURRENT_WORKSPACE_PAST_CREDIT_USAGE](state, past) {
    if (past) {
      state.currentWorkspacePastCreditUsage = {
        ...state.currentWorkspacePastCreditUsage,
        ...{ [`${past.month}-${past.year}`]: past }
      };
    } else {
      state.currentWorkspacePastCreditUsage = {};
    }
  },
  [MutationTypes.SET_CURRENT_WORKSPACE_USERS](state, { users, page }) {
    state.currentWorkspaceUsers = {
      list: [...users],
      ...page
    };
  },
  [MutationTypes.UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES](
    state,
    { id, roles }
  ) {
    state.currentWorkspaceUsers = {
      ...state.currentWorkspaceUsers,
      list: state.currentWorkspaceUsers.list
        ? state.currentWorkspaceUsers.list.map(u =>
            u.id === id ? { ...u, roles } : u
          )
        : null
    };
  },
  [MutationTypes.TOGGLE_WORKSPACE_VISIBILITY](state, id) {
    if (state.workspaceSummaries) {
      state.workspaceSummaries = state.workspaceSummaries.map(w =>
        w.id === id ? { ...w, isFavorite: !w.isFavorite } : w
      );
    }
    if (state.currentWorkspace && state.currentWorkspace.id === id) {
      state.currentWorkspace = {
        ...state.currentWorkspace,
        isFavorite: !state.currentWorkspace.isFavorite
      };
    }
    if (state.filteredWorkspaces.list && state.filteredWorkspaces.list[id]) {
      state.filteredWorkspaces = {
        ...state.filteredWorkspaces,
        list: {
          ...state.filteredWorkspaces.list,
          [id]: {
            ...state.filteredWorkspaces.list[id],
            isFavorite: !state.filteredWorkspaces.list[id].isFavorite
          }
        }
      };
    }
  },
  [MutationTypes.ADD_CURRENT_WORKSPACE_USER](state, user) {
    state.currentWorkspaceUsers = {
      ...state.currentWorkspaceUsers,
      list: [
        ...(state.currentWorkspaceUsers.list || []).filter(
          u => u.id !== user.id
        ),
        user
      ]
    };
  },
  [MutationTypes.UPDATE_CURRENT_WORKSPACE_USER](state, user) {
    state.currentWorkspaceUsers = {
      ...state.currentWorkspaceUsers,
      list: (state.currentWorkspaceUsers.list || []).map(u =>
        u.id === user.id ? user : u
      )
    };
  },
  [MutationTypes.DELETE_CURRENT_WORKSPACE_USER](state, id) {
    state.currentWorkspaceUsers = {
      ...state.currentWorkspaceUsers,
      list: (state.currentWorkspaceUsers.list || []).filter(u => u.id !== id)
    };
  },
  [MutationTypes.SET_POTENTIAL_WORKSPACE_USERS](state, users) {
    state.potentialWorkspaceUsers = users;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
