import {
  OrganizationWorkspace,
  OrganizationWorkspaceUser
} from "./../generated/graphql";

export type MonthYear = {
  month: number;
  year: number;
};

export type WorkspaceSummary = Pick<
  OrganizationWorkspace,
  "company" | "id" | "isFavorite" | "status"
> & {
  subscription: {
    id?: string;
  };
};

export type FilteredWorkspace = WorkspaceSummary &
  Pick<OrganizationWorkspace, "users"> & {
    subscription: {
      plan: {
        name: string | null;
        changeable: boolean | null;
      };
    };
  };

export type WorkspaceFilter = {
  name: string;
  price: string | null;
  status: string | null;
};

export type DashboardWorkspaceFilter = Pick<WorkspaceFilter, "name" | "status">;

export type WorkspaceUserFilter = {
  name: string;
  role: string;
};

export type WorkspaceUserInvite = Omit<OrganizationWorkspaceUser, "id">;

export const toWorkspaceSummary = ({
  id,
  company,
  isFavorite,
  status,
  subscription
}: OrganizationWorkspace): WorkspaceSummary => {
  return {
    id,
    company,
    isFavorite,
    status,
    subscription: {
      id: subscription?.id
    }
  };
};

export const toFilteredWorkspace = ({
  id,
  company,
  isFavorite,
  status,
  users,
  subscription
}: OrganizationWorkspace): FilteredWorkspace => {
  const planName =
    subscription && subscription.plan ? subscription.plan.name : null;
  const planChangeable =
    subscription && subscription.plan ? subscription.plan.changeable : null;
  return {
    id,
    company,
    isFavorite,
    status,
    users,
    subscription: { plan: { name: planName, changeable: planChangeable } }
  };
};
