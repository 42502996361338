import { Modules } from "./index";
import { getAuthToken, getRefreshToken } from "@/auth";
import {
  OrganizationMessage,
  RoleController,
  User
} from "./../generated/graphql";
const getLocale = () => {
  let locale = "en-US";
  if (
    window.navigator &&
    window.navigator.languages &&
    window.navigator.languages.length > 0
  ) {
    locale = window.navigator.languages[0];
  }
  return locale;
};

export interface RootState {
  organizationId: string | null;
  locale: string;
  isLoading: boolean;
  authToken: string | null;
  refreshToken: string | null;
  loggedUser: User | null;
  bannerText: OrganizationMessage | null;
  roles: {
    workspace: RoleController;
    organization: RoleController;
  };
}

export type RootStateAndModules = RootState & Modules;

export const getDefaultState: () => RootState = () => ({
  organizationId: null,
  locale: getLocale(),
  isLoading: false,
  authToken: getAuthToken(),
  refreshToken: getRefreshToken(),
  loggedUser: null,
  bannerText: null,
  roles: {
    organization: { roles: [] },
    workspace: { roles: [] }
  }
});
