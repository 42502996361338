import { KeyEventEnum } from "./enum";
import { Maybe } from "./generated/graphql";
export const isEqual = (obj1: string, obj2: string) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const isObject = (val: unknown) => {
  if (val === null) {
    return false;
  }
  return typeof val === "function" || typeof val === "object";
};
export const filterDup = (arr: string[]) => {
  return Array.from(new Set(arr.map(el => JSON.stringify(el)))).map(el =>
    JSON.parse(el)
  );
};

export const debounce = function<F extends (...args: unknown[]) => unknown>(
  fn: F,
  time = 400
) {
  let timeout: NodeJS.Timeout | null;
  return function(...args: Parameters<F>) {
    const later = () => {
      timeout = null;
      fn(...args);
    };
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, time);
  };
};

type UnknwownObject = { [key: string]: unknown | unknown[] | UnknwownObject };

export function omitDeep(
  value: unknown | unknown[] | UnknwownObject,
  key: string
): unknown {
  if (Array.isArray(value)) {
    return value.map(i => omitDeep(i, key));
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key) return newObject;
      return Object.assign(
        { [k]: omitDeep((value as UnknwownObject)[k], key) },
        newObject
      );
    }, {});
  }
  return value;
}

export const upsert = <V, K extends keyof V>(
  arr: V[],
  key: K,
  newVal: V,
  forceUpdate = true
) =>
  arr.map(val =>
    val[key] === newVal[key]
      ? forceUpdate
        ? newVal
        : { ...val, ...newVal }
      : val
  );

export const compareFields = <
  V extends { [key: string]: string | null },
  K extends keyof V
>(
  a: V,
  b: V,
  fields: Array<K>
) => {
  let af: Maybe<string>, bf: Maybe<string>;
  for (const f of fields) {
    af = a[f];
    bf = b[f];

    if (af !== null && bf !== null) {
      const res = af.localeCompare(bf);
      if (res !== 0) {
        return res;
      } else {
        continue;
      }
    } else if (af !== null && bf === null) {
      return -1;
    } else if (af === null && bf !== null) {
      return 1;
    }
  }
  return 0;
};

export const handleKeyNavigation = (
  listSelector: string,
  inputSelector: string,
  selectedClass: string,
  e: KeyboardEvent,
  onSuccess: (id: string) => void
) => {
  if (e.key === KeyEventEnum.ArrowUp || e.key === KeyEventEnum.ArrowDown) {
    const el = document.querySelector(listSelector);
    if (el) {
      const selectedWs = document.querySelector(
        `${listSelector} .${selectedClass}`
      );
      if (e.key === KeyEventEnum.ArrowUp) {
        if (selectedWs && el.firstElementChild === selectedWs) {
          selectedWs.classList.remove(selectedClass);
          el.lastElementChild?.classList.add(selectedClass);
        } else if (selectedWs && el.firstElementChild !== selectedWs) {
          selectedWs.classList.remove(selectedClass);
          selectedWs.previousElementSibling?.classList.add(selectedClass);
        } else {
          el.lastElementChild?.classList.add(selectedClass);
        }
      } else {
        if (selectedWs && el.lastElementChild === selectedWs) {
          selectedWs.classList.remove(selectedClass);
          el.firstElementChild?.classList.add(selectedClass);
        } else if (selectedWs && el.lastElementChild !== selectedWs) {
          selectedWs.classList.remove(selectedClass);
          selectedWs.nextElementSibling?.classList.add(selectedClass);
        } else {
          el.firstElementChild?.classList.add(selectedClass);
        }
      }

      const finalSelection = document.querySelector(
        `${listSelector} .${selectedClass}`
      ) as HTMLElement;
      const input = document.querySelector(inputSelector) as HTMLElement;
      finalSelection.focus();
      input.focus();
    }
  } else if (e.key === KeyEventEnum.Enter) {
    const selectedWs = document.querySelector(
      `${listSelector} .${selectedClass}`
    );
    if (selectedWs) {
      onSuccess(selectedWs.id);
    }
  }
};

export const isInViewport = function(elem: Element) {
  const bounding = elem.getBoundingClientRect();

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (elem.parentElement?.clientHeight ||
        document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};
