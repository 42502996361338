/**
 * Enum of all action types available in the store.
 * Convention is that each action name should be prefixed by its module name
 * https://docs.vuestorefront.io/guide/vuex/vuex-conventions.html
 */
export enum ActionTypes {
  RESET_STORE = "RESET_STORE",
  SETUP_ORGANIZATION = "SETUP_ORGANIZATION",
  SETUP_LOGGED_USER = "SETUP_LOGGED_USER",
  GET_ROLES = "GET_ROLES",
  LOGOUT_USER = "LOGOUT_USER",
  REFRESH_SESSION = "REFRESH_SESSION",
  PATCH_LOGGED_USER = "PATCH_LOGGED_USER",
  GET_BANNER_TEXT = "GET_BANNER_TEXT",

  // Dashboard
  FETCH_DASHBOARD_DATA = "FETCH_DASHBOARD_DATA",
  FETCH_DASHBOARD_WORKSPACES = "FETCH_DASHBOARD_WORKSPACES",
  LOAD_MORE_FAVOURITE_WORKSPACES = "LOAD_MORE_FAVOURITE_WORKSPACES",

  // Organization
  FETCH_ORGANIZATION_SUMMARIES = "FETCH_ORGANIZATION_SUMMARIES",
  FETCH_ORGANIZATION = "FETCH_ORGANIZATION",
  UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION",
  FETCH_PLANS = "FETCH_PLANS",
  FETCH_INVOICES = "FETCH_INVOICES",

  // Billing
  FETCH_BILLING = "FETCH_BILLING",
  UPDATE_ORGANIZATION_BILLING = "UPDATE_ORGANIZATION_BILLING",

  // Payment Method
  FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS",
  DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
  MARK_METHOD_AS_DEFAULT = "MARK_METHOD_AS_DEFAULT",
  CREATE_SETUP_INDENT = "CREATE_SETUP_INDENT",
  CANCEL_SETUP_INDENT = "CANCEL_SETUP_INDENT",

  // User
  FETCH_USERS = "FETCH_USERS",
  INVITE_USER = "INVITE_USER",
  REINVITE_USER = "REINVITE_USER",
  RESEND_USER_INVITE = "RESEND_USER_INVITE",
  DELETE_USER = "DELETE_USER",
  UPDATE_ORGANIZATION_USER_ROLES = "UPDATE_ORGANIZATION_USER_ROLES",

  // Workspace
  FETCH_WORKSPACE_SUMMARIES = "FETCH_WORKSPACE_SUMMARIES",
  FILTER_WORKSPACES = "FILTER_WORKSPACES",
  RESET_WORKSPACES = "RESET_WORKSPACES",
  FETCH_CURRENT_WORKSPACE = "FETCH_CURRENT_WORKSPACE",
  UPDATE_CURRENT_WORKSPACE = "UPDATE_CURRENT_WORKSPACE",
  FETCH_CURRENT_WORKSPACE_SUBSCRIPTION = "FETCH_CURRENT_WORKSPACE_SUBSCRIPTION",
  FETCH_CURRENT_WORKSPACE_ELIGIBLE_PLANS = "FETCH_CURRENT_WORKSPACE_ELIGIBLE_PLANS",
  UPDATE_CURRENT_WORKSPACE_PLAN = "UPDATE_CURRENT_WORKSPACE_PLAN",
  FETCH_CURRENT_WORKSPACE_USAGE = "FETCH_CURRENT_WORKSPACE_USAGE",
  FETCH_CURRENT_WORKSPACE_PAST_CREDIT_USAGE = "FETCH_CURRENT_WORKSPACE_PAST_CREDIT_USAGE",
  FETCH_CURRENT_WORKSPACE_USERS = "FETCH_CURRENT_WORKSPACE_USERS",
  RESET_CURRENT_WORKSPACE = "RESET_CURRENT_WORKSPACE",
  CREATE_WORKSPACE = "CREATE_WORKSPACE",
  ACTIVATE_WORKSPACE = "ACTIVATE_WORKSPACE",
  STOP_WORKSPACE = "STOP_WORKSPACE",
  ARCHIVE_WORKSPACE = "ARCHIVE_WORKSPACE",
  TOGGLE_WORKSPACE_VISIBILITY = "TOGGLE_WORKSPACE_VISIBILITY",
  INVITE_WORKSPACE_USER = "INVITE_WORKSPACE_USER",
  REINVITE_WORKSPACE_USER = "REINVITE_WORKSPACE_USER",
  RESEND_INVITE_WORKSPACE_USER = "RESEND_INVITE_WORKSPACE_USER",
  UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES = "UPDATE_ORGANIZATION_WORKSPACE_USER_ROLES",
  DELETE_WORKSPACE_USER = "DELETE_WORKSPACE_USER",
  FETCH_POTENTIAL_WORKSPACE_USERS = "FETCH_POTENTIAL_WORKSPACE_USERS",
  RESET_POTENTIAL_WORKSPACE_USERS = "RESET_POTENTIAL_WORKSPACE_USERS"
}
