import { Page } from "./../../types/types";
import {
  UPDATE_ORGANIZATION_USER_ROLES,
  REMOVE_ORGANIZATION_USER,
  INVITE_ORGANIZATION_USER
} from "./../../graphql/user/mutations";
import { MutationTree } from "vuex";
import { RootState } from "./../state";
import { ActionTree } from "vuex";
import { UserFilter } from "./../../models/user";
import { ActionTypes } from "./../action-types";
import { AugmentedActionContext } from "./../actions";
import {
  InviteUserInput,
  OrganizationMutationContextUpdateOrganizationUserRolesArgs,
  OrganizationUser,
  Role
} from "./../../generated/graphql";
import apolloWrapper from "@/http";
import { GET_USERS } from "@/graphql/user/queries";
import { Paginated } from "@/types/types";

/**********************
 * Types & Interfaces *
 **********************/
export interface UserState {
  users: Paginated<OrganizationUser>;
  userRoles: Role[];
}
export type UserGetters = {};

type UserActionContext = AugmentedActionContext<UserState, UserMutations>;

interface UserActions {
  [ActionTypes.FETCH_USERS](
    { commit }: UserActionContext,
    payload: { filter: UserFilter; loadMore: boolean }
  ): Promise<OrganizationUser[]>;
  [ActionTypes.INVITE_USER](
    { commit }: UserActionContext,
    payload: InviteUserInput
  ): Promise<OrganizationUser>;
  [ActionTypes.REINVITE_USER](
    { commit }: UserActionContext,
    payload: InviteUserInput
  ): Promise<OrganizationUser>;
  [ActionTypes.DELETE_USER](
    { commit }: UserActionContext,
    id: string
  ): Promise<boolean>;
  [ActionTypes.UPDATE_ORGANIZATION_USER_ROLES](
    { commit }: UserActionContext,
    payload: OrganizationMutationContextUpdateOrganizationUserRolesArgs
  ): Promise<void>;
}

enum MutationTypes {
  SET_USERS = "SET_USERS",
  ADD_USER = "ADD_USER",
  PATCH_USER_ROLE = "PATCH_USER_ROLE",
  DELETE_USER = "DELETE_USER",
  SET_USERS_ROLES = "SET_USERS_ROLES"
}

type UserMutations = {
  [MutationTypes.SET_USERS](
    state: UserState,
    payload: { users: OrganizationUser[]; page: Page }
  ): void;
  [MutationTypes.ADD_USER](state: UserState, user: OrganizationUser): void;
  [MutationTypes.PATCH_USER_ROLE](
    state: UserState,
    { id, roles }: { id: string; roles: Role[] }
  ): void;
  [MutationTypes.DELETE_USER](state: UserState, id: string): void;
  [MutationTypes.SET_USERS_ROLES](state: UserState, roles: Role[]): void;
};

/**********************
 *       State        *
 **********************/
export const state: UserState = {
  users: {
    list: null,
    hasNextPage: false,
    endCursor: ""
  },
  userRoles: []
};

/**********************
 *      Getters       *
 **********************/
const getters = {};

/**********************
 *      Actions       *
 **********************/
const actions: ActionTree<UserState, RootState> & UserActions = {
  async [ActionTypes.FETCH_USERS](
    { commit, state },
    { filter, loadMore = false }
  ) {
    const cursor = loadMore ? state.users.endCursor : "";

    const { data } = await apolloWrapper.query(
      {
        query: GET_USERS,
        variables: { ...filter, cursor }
      },
      false
    );
    const users = data.organizationContext.users.edges.map(u => u.node);
    const page = {
      hasNextPage: data.organizationContext.users.pageInfo.hasNextPage,
      endCursor: data.organizationContext.users.pageInfo.endCursor || ""
    };
    commit(MutationTypes.SET_USERS, {
      users: loadMore ? [...(state.users.list || []), ...users] : users,
      page
    });
    return users;
  },
  async [ActionTypes.INVITE_USER]({ commit }, input) {
    const { data } = await apolloWrapper.mutate({
      mutation: INVITE_ORGANIZATION_USER,
      variables: { input }
    });
    const invitedUser = data?.organizationContext?.inviteOrganizationUser
      .organizationUser as OrganizationUser;
    commit(MutationTypes.ADD_USER, invitedUser);
    return invitedUser;
  },
  async [ActionTypes.REINVITE_USER]({ commit }, input) {
    const { data } = await apolloWrapper.mutate({
      mutation: INVITE_ORGANIZATION_USER,
      variables: { input }
    });
    const invitedUser = data?.organizationContext?.inviteOrganizationUser
      .organizationUser as OrganizationUser;
    commit(MutationTypes.ADD_USER, invitedUser);
    return invitedUser;
  },
  async [ActionTypes.DELETE_USER]({ commit }, id) {
    await apolloWrapper.mutate({
      mutation: REMOVE_ORGANIZATION_USER,
      variables: { id }
    });
    commit(MutationTypes.DELETE_USER, id);
    return true;
  },
  async [ActionTypes.UPDATE_ORGANIZATION_USER_ROLES]({ commit }, payload) {
    const { data } = await apolloWrapper.mutate({
      mutation: UPDATE_ORGANIZATION_USER_ROLES,
      variables: payload
    });
    const roles = data?.organizationContext?.updateOrganizationUserRoles
      ?.roles as Role[];
    commit(MutationTypes.PATCH_USER_ROLE, { id: payload.id, roles });
  }
};

/**********************
 *     Mutations      *
 **********************/
const mutations: MutationTree<UserState> & UserMutations = {
  [MutationTypes.SET_USERS](state, { users, page }) {
    state.users = { list: [...users], ...page };
  },
  [MutationTypes.ADD_USER](state, user) {
    state.users = {
      ...state.users,
      list: state.users.list
        ? [...state.users.list.filter(u => u.id !== user.id), user]
        : [user]
    };
  },
  [MutationTypes.PATCH_USER_ROLE](state, { id, roles }) {
    state.users = {
      ...state.users,
      list: state.users.list
        ? state.users.list.map(u => (u.id === id ? { ...u, roles } : u))
        : []
    };
  },
  [MutationTypes.DELETE_USER](state, id) {
    state.users = {
      ...state.users,
      list: state.users.list ? state.users.list.filter(u => u.id !== id) : []
    };
  },
  [MutationTypes.SET_USERS_ROLES](state, roles) {
    state.userRoles = [...roles];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
