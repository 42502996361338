export enum MutationTypes {
  RESET_STATE = "RESET_STATE",
  SET_LOADING = "SET_LOADING",
  SET_TOKEN = "SET_TOKEN",
  SET_ORGANIZATION_ID = "SET_ORGANIZATION_ID",
  SET_LOGGED_USER = "SET_LOGGED_USER",
  SET_ROLES = "SET_ROLES",
  LOGOUT_USER = "LOGOUT_USER",
  PATCH_LOGGED_USER = "PATCH_LOGGED_USER",
  SET_BANNER_TEXT = "SET_BANNER_TEXT"
}
