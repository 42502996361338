import { VueConstructor } from "vue/types/umd";

export const bubble = function $bubble(
  this: Vue,
  eventName: string,
  ...args: unknown[]
) {
  // Emit the event on all parent components
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  let component = this;

  do {
    component.$emit(eventName, ...args);
    component = component.$parent;
  } while (component);
};

export default (Vue: VueConstructor) => {
  Vue.prototype.$bubble = bubble;
};
