import {
  CANCEL_SETUP_INDENT,
  UPDATE_ORGANIZATION_DEFAULT_PAYMENT_METHOD,
  DETACH_ORGANIZATION_PAYMENT_METHOD
} from "./../../graphql/paymentMethod/mutations";
import { MutationTree } from "vuex";
import { RootState } from "../state";
import { ActionTree } from "vuex";
import { ActionTypes } from "../action-types";
import { AugmentedActionContext } from "../actions";
import {
  CardPaymentMethod,
  PaymentMethodUnion,
  PaymentMethodIntentPayload
} from "../../generated/graphql";
import apolloWrapper from "@/http";
import { GET_PAYMENT_METHODS } from "@/graphql/paymentMethod/queries";
import { SETUP_INDENT } from "@/graphql/paymentMethod/mutations";
import { SetupIntentStatus } from "@/enum";

/**********************
 * Types & Interfaces *
 **********************/
export interface PaymentMethodState {
  paymentMethodList: PaymentMethodUnion[] | null;
  stripeSetupIntent: PaymentMethodIntentPayload | null;
}

export type PaymentMethodGetters = {};

type PaymentMethodActionContext = AugmentedActionContext<
  PaymentMethodState,
  PaymentMethodMutations
>;

interface PaymentMethodActions {
  [ActionTypes.FETCH_PAYMENT_METHODS]({
    commit
  }: PaymentMethodActionContext): Promise<CardPaymentMethod[]>;
  [ActionTypes.DELETE_PAYMENT_METHOD](
    { commit }: PaymentMethodActionContext,
    payload: string
  ): Promise<boolean>;
  [ActionTypes.MARK_METHOD_AS_DEFAULT](
    { commit }: PaymentMethodActionContext,
    payload: string
  ): Promise<boolean>;
  [ActionTypes.CREATE_SETUP_INDENT]({
    commit
  }: PaymentMethodActionContext): Promise<PaymentMethodIntentPayload>;
  [ActionTypes.CANCEL_SETUP_INDENT]({
    commit
  }: PaymentMethodActionContext): Promise<boolean>;
}

export enum MutationTypes {
  SET_PAYMENT_METHODS = "SET_PAYMENT_METHODS",
  ADD_METHOD = "ADD_METHOD",
  DELETE_METHOD = "DELETE_METHOD",
  MARK_AS_DEFAULT = "MARK_AS_DEFAULT",
  SET_SETUP_INTENT = "SET_SETUP_INTENT"
}

type PaymentMethodMutations = {
  [MutationTypes.SET_PAYMENT_METHODS](
    state: PaymentMethodState,
    payload: CardPaymentMethod[]
  ): void;
  [MutationTypes.ADD_METHOD](
    state: PaymentMethodState,
    payload: CardPaymentMethod
  ): void;
  [MutationTypes.DELETE_METHOD](state: PaymentMethodState, key: string): void;
  [MutationTypes.MARK_AS_DEFAULT](state: PaymentMethodState, key: string): void;
  [MutationTypes.SET_SETUP_INTENT](
    state: PaymentMethodState,
    payload: PaymentMethodIntentPayload | null
  ): void;
};

/**********************
 *       State        *
 **********************/
const state: PaymentMethodState = {
  paymentMethodList: null,
  stripeSetupIntent: null
};

/**********************
 *      Getters       *
 **********************/
const getters = {};

/**********************
 *      Actions       *
 **********************/
const actions: ActionTree<PaymentMethodState, RootState> &
  PaymentMethodActions = {
  async [ActionTypes.FETCH_PAYMENT_METHODS]({ commit }) {
    const { data } = await apolloWrapper.query({
      query: GET_PAYMENT_METHODS,
      fetchPolicy: "network-only" // Force cache refresh
    });
    const paymentMethods = data.organizationContext
      .paymentMethods as CardPaymentMethod[];
    commit(MutationTypes.SET_PAYMENT_METHODS, paymentMethods);
    return paymentMethods;
  },

  async [ActionTypes.DELETE_PAYMENT_METHOD]({ commit }, paymentMethodId) {
    const { data } = await apolloWrapper.mutate({
      mutation: DETACH_ORGANIZATION_PAYMENT_METHOD,
      variables: { paymentMethodId }
    });
    const res = data?.organizationContext?.detachOrganizationPaymentMethod;
    if (res) {
      commit(MutationTypes.DELETE_METHOD, paymentMethodId);
    }
    return res as boolean;
  },

  async [ActionTypes.MARK_METHOD_AS_DEFAULT]({ commit }, id) {
    await apolloWrapper.mutate({
      mutation: UPDATE_ORGANIZATION_DEFAULT_PAYMENT_METHOD,
      variables: { id }
    });
    commit(MutationTypes.MARK_AS_DEFAULT, id);
    return Boolean(id);
  },

  async [ActionTypes.CREATE_SETUP_INDENT]({ commit }) {
    const { data } = await apolloWrapper.mutate(
      {
        mutation: SETUP_INDENT
      },
      false
    );
    const setupIntent = data!.organizationContext!.createPaymentMethodIntent;
    commit(MutationTypes.SET_SETUP_INTENT, setupIntent);

    return setupIntent;
  },

  async [ActionTypes.CANCEL_SETUP_INDENT]({ commit, state }) {
    if (state.stripeSetupIntent) {
      if (
        ![SetupIntentStatus.canceled, SetupIntentStatus.succeeded].includes(
          state.stripeSetupIntent.status as SetupIntentStatus
        )
      ) {
        await apolloWrapper.mutate(
          {
            mutation: CANCEL_SETUP_INDENT,
            variables: { id: state.stripeSetupIntent.id }
          },
          false
        );
        commit(MutationTypes.SET_SETUP_INTENT, null);
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
};

/**********************
 *     Mutations      *
 **********************/
const mutations: MutationTree<PaymentMethodState> & PaymentMethodMutations = {
  [MutationTypes.SET_PAYMENT_METHODS](state, methods) {
    state.paymentMethodList = [...methods];
  },
  [MutationTypes.ADD_METHOD](state, method) {
    state.paymentMethodList = state.paymentMethodList
      ? [...state.paymentMethodList, method]
      : [method];
  },
  [MutationTypes.DELETE_METHOD](state, id) {
    state.paymentMethodList = state.paymentMethodList
      ? state.paymentMethodList.filter(o => o.id !== id)
      : null;
  },
  [MutationTypes.MARK_AS_DEFAULT](state, id) {
    state.paymentMethodList = state.paymentMethodList
      ? state.paymentMethodList.map(o => ({
          ...o,
          default: o.id === id
        }))
      : null;
  },
  [MutationTypes.SET_SETUP_INTENT](state, setupIntent) {
    state.stripeSetupIntent = setupIntent;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
