import Vue from "vue";
import Vuex from "vuex";
import organization, { OrgState } from "./modules/organization";
import dashboard, { DashboardState } from "./modules/dashboard";
import workspace, { WorkspaceState } from "./modules/workspace";
import billing, { BillingState } from "./modules/billing";
import paymentMethod, { PaymentMethodState } from "./modules/paymentMethod";
import user, { UserState } from "./modules/user";

import { Store as VuexStore, CommitOptions, DispatchOptions } from "vuex";
import { getDefaultState, RootState } from "./state";
import { Getters, getters } from "./getters";
import { Mutations, mutations } from "./mutations";
import { Actions, actions } from "./actions";

Vue.use(Vuex);

export type Store = Omit<
  VuexStore<RootState>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload?: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

const modules = {
  dashboard,
  organization,
  billing,
  paymentMethod,
  user,
  workspace
};

export type Modules = {
  dashboard: DashboardState;
  organization: OrgState;
  billing: BillingState;
  paymentMethod: PaymentMethodState;
  user: UserState;
  workspace: WorkspaceState;
};

const store = new VuexStore<RootState>({
  strict: process.env.NODE_ENV !== "production",
  state: getDefaultState(),
  getters,
  mutations,
  actions,
  modules
}) as Store;

export default store;

const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetStore() {
  store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)));
}
