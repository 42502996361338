import { Organization, Maybe, Plan, PriceTierType } from "../generated/graphql";
export type OrganizationModel = Omit<Organization, "id"> & {
  id: Maybe<string>;
};

export type OrganizationSummary = Pick<Organization, "id" | "logoUrl" | "name">;

export type EnhancedPlan = Plan & {
  flatLimit: number;
  flatAmount: number;
  overageAmount: number;
};

export const toEnhancedPlan = (plan: Plan): EnhancedPlan => {
  const flatLimit =
    plan.priceTiers.find(t => t && t.type === PriceTierType.Flat)?.limit || 0;
  const flatAmount =
    plan.priceTiers.find(t => t && t.type === PriceTierType.Flat)?.amount || 0;
  const overageAmount =
    plan.priceTiers.find(t => t && t.type === PriceTierType.Unit)?.amount || 0;
  return {
    ...plan,
    flatLimit,
    flatAmount,
    overageAmount
  };
};

export const toPlan = (plan: EnhancedPlan): Plan => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { flatLimit, flatAmount, overageAmount, ...rest } = plan;
  return rest;
};
