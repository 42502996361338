import { MutationTree } from "vuex";
import { ActionTree } from "vuex";
import { RootState } from "./../state";
import { AugmentedActionContext } from "./../actions";
import { ActionTypes } from "./../action-types";
import { Billing, BillingInput } from "./../../generated/graphql";
import apolloWrapper from "@/http";
import { FETCH_BILLING } from "@/graphql/account/queries";
import { UPDATE_ORGANIZATION_BILLING } from "../../graphql/account/mutations";
import { countries } from "@/list";

/**********************
 * Types & Interfaces *
 **********************/
export interface BillingState {
  billing: Billing;
  isDeleting: boolean;
  isUpserting: boolean;
  isPatching: boolean;
}
export type BillingGetters = {};

type BillingActionContext = AugmentedActionContext<
  BillingState,
  BillingMutations
>;

interface BillingActions {
  [ActionTypes.FETCH_BILLING]({
    commit
  }: BillingActionContext): Promise<Billing>;
  [ActionTypes.UPDATE_ORGANIZATION_BILLING](
    { commit }: BillingActionContext,
    payload: Billing
  ): Promise<Billing>;
}

enum MutationTypes {
  SET_BILLING = "SET_BILLING",
  SET_PLANS = "SET_PLANS",
  SET_INVOICES = "SET_INVOICES"
}

type BillingMutations = {
  [MutationTypes.SET_BILLING](state: BillingState, payload: Billing): void;
};

/**********************
 *       State        *
 **********************/
const state: BillingState = {
  billing: {
    name: "",
    addressLine1: "",
    addressLine2: "",
    state: "",
    zip: "",
    city: "",
    country: countries[0],
    currency: "",
    vat: "",
    recipients: []
  },
  isDeleting: false,
  isUpserting: false,
  isPatching: false
};

/**********************
 *      Getters       *
 **********************/
const getters = {};

/**********************
 *      Actions       *
 **********************/
const actions: ActionTree<BillingState, RootState> & BillingActions = {
  async [ActionTypes.FETCH_BILLING]({ commit }) {
    const { data } = await apolloWrapper.query({
      query: FETCH_BILLING,
      fetchPolicy: "network-only"
    });
    const billing = data.organizationContext.billing;
    if (billing) {
      commit(MutationTypes.SET_BILLING, billing);
    }
    return billing as Billing;
  },

  async [ActionTypes.UPDATE_ORGANIZATION_BILLING]({ commit }, billing) {
    const input = billing as BillingInput;
    const { data } = await apolloWrapper.mutate({
      mutation: UPDATE_ORGANIZATION_BILLING,
      variables: { input }
    });
    const response = data!.organizationContext!.updateOrganizationBilling
      .billing;
    commit(MutationTypes.SET_BILLING, response);
    return response;
  }
};

/**********************
 *     Mutations      *
 **********************/
const mutations: MutationTree<BillingState> & BillingMutations = {
  [MutationTypes.SET_BILLING](state, billing) {
    state.billing = billing;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
