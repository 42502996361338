import { getLoggedUser } from "@/auth";
import { ActionTypes } from "./../action-types";
import { GetterTree, ActionTree, MutationTree } from "vuex";
import {
  Organization,
  Plan,
  Invoice,
  Maybe,
  OrganizationInput,
  OrganizationStatus
} from "./../../generated/graphql";
import apolloWrapper from "@/http";
import {
  GET_CURRENT_ORGANIZATION,
  GET_PLANS,
  GET_INVOICES,
  GET_ORGANIZATION_LIST
} from "@/graphql/organizations/queries";
import { UPDATE_CURRENT_ORGANIZATION } from "@/graphql/organizations/mutations";
import { compareDesc, format } from "date-fns";
import { RootState } from "../state";
import { AugmentedActionContext } from "../actions";
import {
  EnhancedPlan,
  OrganizationModel,
  OrganizationSummary,
  toEnhancedPlan
} from "@/models/organization";

/**********************
 * Types & Interfaces *
 **********************/
export interface OrgState {
  organizationSummaries: OrganizationSummary[] | null;
  organization: OrganizationModel;
  plans: { [key: string]: EnhancedPlan[] };
  invoices: Invoice[] | null;
}
export type OrgGetters = {
  getInvoicesByMonthYear(state: OrgState): GroupedInvoices[] | null;
  getPlans(state: OrgState, getters: any, rootState: RootState): EnhancedPlan[];
};
type InvoiceWithMonthYear = Invoice & {
  monthYearStr: string;
};

export type GroupedInvoices = {
  invoices: InvoiceWithMonthYear[];
  period: string;
  amount: number;
  currency: string;
};

type OrgActionContext = AugmentedActionContext<OrgState, OrgMutations>;

interface OrgActions {
  [ActionTypes.FETCH_ORGANIZATION_SUMMARIES]({
    commit
  }: OrgActionContext): Promise<OrganizationSummary[]>;
  [ActionTypes.FETCH_ORGANIZATION](
    { commit }: OrgActionContext,
    noCache?: boolean
  ): Promise<Organization>;
  [ActionTypes.UPDATE_ORGANIZATION](
    { commit }: OrgActionContext,
    payload: OrganizationInput
  ): Promise<Organization | undefined>;
  [ActionTypes.FETCH_PLANS](
    { commit }: OrgActionContext,
    orgId: string
  ): Promise<Maybe<Plan[]> | null>;
  [ActionTypes.FETCH_INVOICES]({
    commit
  }: OrgActionContext): Promise<Maybe<Invoice[]>>;
}

enum MutationTypes {
  SET_ORGANIZATION = "SET_ORGANIZATION",
  SET_ORGANIZATION_SUMMARIES = "SET_ORGANIZATION_SUMMARIES",
  SET_PLANS = "SET_PLANS",
  SET_INVOICES = "SET_INVOICES"
}

type OrgMutations = {
  [MutationTypes.SET_ORGANIZATION](
    state: OrgState,
    payload: Organization
  ): void;
  [MutationTypes.SET_ORGANIZATION_SUMMARIES](
    state: OrgState,
    payload: OrganizationSummary[]
  ): void;
  [MutationTypes.SET_PLANS](
    state: OrgState,
    payload: { plans: Plan[]; orgId: string }
  ): void;
  [MutationTypes.SET_INVOICES](state: OrgState, payload: Invoice[]): void;
};

/**********************
 *       State        *
 **********************/
export const state: OrgState = {
  organization: {
    id: null,
    name: "",
    contact: {
      firstName: "",
      lastName: "",
      email: "",
      phone: {
        number: "",
        localNumber: "",
        internationalNumber: "",
        countryCode: ""
      }
    },
    addressLine1: "",
    addressLine2: "",
    state: "",
    status: OrganizationStatus.Pending,
    zip: "",
    city: "",
    country: {
      code: "FR",
      name: "France"
    },
    logoUrl: ""
  },
  plans: {},
  invoices: null,
  organizationSummaries: null
};

/**********************
 *      Getters       *
 **********************/
const getters: GetterTree<OrgState, RootState> & OrgGetters = {
  getInvoicesByMonthYear: state => {
    if (!state.invoices) {
      return null;
    } else {
      const arr = state.invoices.reduce((acc, val) => {
        const newVal = {
          ...val,
          monthYearStr: format(new Date(val.year, val.month - 1), "MMM yyyy")
        };
        acc[newVal.monthYearStr] = acc[newVal.monthYearStr]
          ? [...acc[newVal.monthYearStr], newVal]
          : [newVal];
        return acc;
      }, {} as { [key: string]: InvoiceWithMonthYear[] });
      return Object.entries(arr)
        .sort((a, b) => {
          return a[1][0].number.localeCompare(b[1][0].number);
        })
        .map(([period, invoices]) => ({
          period,
          invoices,
          amount: invoices.reduce((acc, val) => acc + val.amount, 0),
          currency: invoices[0].currency
        }))
        .sort((a, b) => compareDesc(new Date(a.period), new Date(b.period)));
    }
  },
  getPlans: (state, _, rootState) => {
    return rootState.organizationId && state.plans[rootState.organizationId]
      ? state.plans[rootState.organizationId]
      : [];
  }
};

/**********************
 *      Actions       *
 **********************/
const actions: ActionTree<OrgState, RootState> & OrgActions = {
  async [ActionTypes.FETCH_ORGANIZATION_SUMMARIES]({ commit }) {
    const loggedUser = getLoggedUser();
    if (loggedUser) {
      const { data } = await apolloWrapper.query({
        query: GET_ORGANIZATION_LIST,
        variables: { id: loggedUser.id }
      });
      const result = data.organizations.organizations as Organization[];
      commit(MutationTypes.SET_ORGANIZATION_SUMMARIES, result);
      return result;
    } else {
      throw new Error(
        "Authentication error: Cannot fetch organization list without a token in session."
      );
    }
  },
  async [ActionTypes.FETCH_ORGANIZATION]({ commit }) {
    const { data } = await apolloWrapper.query({
      query: GET_CURRENT_ORGANIZATION,
      fetchPolicy: "network-only"
    });
    const organization = data.organizationContext.organization;
    commit(MutationTypes.SET_ORGANIZATION, organization);
    return organization;
  },

  async [ActionTypes.UPDATE_ORGANIZATION]({ commit }, input) {
    const { data } = await apolloWrapper.mutate({
      mutation: UPDATE_CURRENT_ORGANIZATION,
      variables: { input }
    });
    const organization = data?.organizationContext?.updateOrganization
      .organization as Organization | undefined;
    commit(MutationTypes.SET_ORGANIZATION, organization);
    return organization;
  },

  async [ActionTypes.FETCH_PLANS]({ commit, state, rootState }) {
    if (rootState.organizationId) {
      if (!state.plans[rootState.organizationId]) {
        const { data } = await apolloWrapper.query(
          {
            query: GET_PLANS,
            fetchPolicy: "cache-first"
          },
          false
        );
        const plans = data.organizationContext.plans as Plan[];
        commit(MutationTypes.SET_PLANS, {
          plans,
          orgId: rootState.organizationId
        });
        return plans;
      } else {
        return state.plans[rootState.organizationId];
      }
    } else {
      return null;
    }
  },
  async [ActionTypes.FETCH_INVOICES]({ commit }) {
    const { data } = await apolloWrapper.query({
      query: GET_INVOICES
    });
    const invoices = data.organizationContext.invoices as Invoice[];
    commit(MutationTypes.SET_INVOICES, invoices);
    return invoices;
  }
};

/**********************
 *     Mutations      *
 **********************/
const mutations: MutationTree<OrgState> & OrgMutations = {
  [MutationTypes.SET_ORGANIZATION](state, payload: Organization) {
    state.organization = payload;
    return state;
  },
  [MutationTypes.SET_ORGANIZATION_SUMMARIES](state, payload) {
    state.organizationSummaries = payload;
  },
  [MutationTypes.SET_PLANS](state, { plans, orgId }) {
    state.plans = {
      ...state.plans,
      [orgId]: plans
        .map(toEnhancedPlan)
        .sort((a, b) => a.flatAmount - b.flatAmount)
    };
  },
  [MutationTypes.SET_INVOICES](state, invoices) {
    state.invoices = [...invoices];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
