import { RouteName } from "./router/index";
import VueApollo from "vue-apollo";
import Vue from "vue";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";
import { ApolloLink } from "apollo-link";
import store from "./store/index";
import schema from "@/generated/graphql.schema.json";
import {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { GraphQLError } from "graphql";
import { errorToast } from "./toast";

// Install the vue plugin
Vue.use(VueApollo);

// Http endpoint
const httpEndpoint =
  process.env.VUE_APP_GRAPHQL_HTTP || "http://localhost:4000/graphql";

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-Organization-Id": store.state.organizationId,
      Authorization: store.state.authToken
    }
  });
  return forward(operation);
});

// Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

Vue.prototype.$filesRoot = filesRoot;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  introspectionQueryResultData: schema as any // FIXME: possibleTypes mismatch types
});

const errorHandlers: { [key: string]: (err: GraphQLError) => void } = {
  TokenExpiredError: () => {
    console.error("Token , ...logging out...");
  },
  InvalidTokenError: ({ message }) =>
    console.error(`[Token error]: Message: ${message}`),
  default: ({ message, locations, path }) => {
    console.error(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    );
  }
};
const errorLink = onError(error => {
  const { graphQLErrors, networkError } = error;
  if (networkError && window.vm) {
    if ("statusCode" in networkError) {
      errorToast(
        window.vm,
        `Error ${networkError.statusCode}: ${networkError.message}`
      );
      if (process.env.NODE_ENV !== "development") {
        window.vm.$router.push({
          name: RouteName.SWITCH_ORG,
          params: { noRedirect: "true" }
        });
      }
    }
  }
  if (graphQLErrors) {
    // Do something about this, ignoring linting error is not a good idea
    // @ts-ignore
    graphQLErrors.map(args =>
      (errorHandlers[args.name] || errorHandlers.default)(args)
    );
    graphQLErrors.forEach(err => {
      // TODO: Move it when it is sent as a HTTP 500 error
      if (err?.extensions?.code === "AUTH_NOT_AUTHORIZED" && window.vm) {
        errorToast(
          window.vm,
          "You were redirected: The current user is not authorized to access this ressource."
        );
        if (process.env.NODE_ENV !== "development") {
          window.vm.$router.push({
            name: RouteName.SWITCH_ORG,
            params: { noRedirect: "true" }
          });
        }
      } else if (window.vm) {
        errorToast(window.vm, err.message);
      }
    });
  }

  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
  }
});

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || "ws://localhost:4000/graphql",
  // LocalStorage token
  // tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: errorLink.concat(authLink),

  typeDefs: schema.__schema.types,

  // Override default cache
  cache: new InMemoryCache({ fragmentMatcher })
  // Override the way the Authorization header is set
  // getAuth: (tokenName) => getUserToken(tokenName),

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

// TODO: Fix the ts-ignore issue
export const { apolloClient, wsClient } = createApolloClient({
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...(defaultOptions as any)
  // ...options
});
export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: "network-only"
      }
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.error(
        "%cError",
        "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
        error.message
      );
    }
  });
  return apolloProvider;
}
