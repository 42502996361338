import {
  GET_DASHBOARD_DATA,
  GET_DASHBOARD_FAVOURITE_WORKSPACES,
  GET_DASHBOARD_WORKSPACES
} from "@/graphql/dashboard/queries";
import { MutationTree } from "vuex";
import { ActionTree } from "vuex";
import { RootState } from "./../state";
import { AugmentedActionContext } from "./../actions";
import { ActionTypes } from "./../action-types";
import apolloWrapper from "@/http";
import { Paginated } from "@/types/types";
import { Status } from "@/generated/graphql";

/**********************
 * Types & Interfaces *
 **********************/
export type DashboardWorkspace = {
  id: string;
  status: Status;
  subscription: {
    plan: {
      name: string;
    };
    usage: {
      current: {
        count: number;
      };
    };
  };
  usage: {
    current: {
      periodEnd: number;
    };
  };
  company: {
    name: string;
    logoUrl: string;
  };
};
export type DashboardInformation = {
  id: string;
  name: string;
  logoUrl: string;
  contact: {
    lastName: string;
    firstName: string;
  };
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: {
    code: string;
    name: string;
  };
  zip: string;
  state: string;
};

export type DashboardInvoices = {
  year: number;
  month: number;
  currency: string;
  amount: number;
  id: string;
  credits: number;
};

export type DashboardBilling = {
  year: number;
  month: number;
  currency: string;
  totalAmount: number;
  totalCredits: number;
};

export type DashboardUser = {
  firstName: string;
  lastName: string;
  id: string;
  pictureUrl: string;
};
export type DashboardQueryReturnType = {
  informations: DashboardInformation;
  billing: DashboardInvoices[];
  users: {
    edges: {
      node: DashboardUser;
    }[];
    totalCount: number;
  };
  favoriteWorkspaces: {
    edges: {
      node: DashboardWorkspace;
    }[];
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
  };
  ws: {
    edges: {
      node: DashboardWorkspace;
    }[];
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
  };
};

export interface DashboardState {
  informations: DashboardInformation | null;
  billing: DashboardBilling | null;
  users: {
    list: DashboardUser[] | null;
    totalCount: number;
  };
  favoriteWorkspaces: Paginated<DashboardWorkspace>;
  workspaces: Paginated<DashboardWorkspace>;
}
export type DashboardGetters = {};

type DashboardActionContext = AugmentedActionContext<
  DashboardState,
  DashboardMutations
>;

interface DashboardActions {
  [ActionTypes.FETCH_DASHBOARD_DATA]({
    commit
  }: DashboardActionContext): Promise<DashboardQueryReturnType>;
  [ActionTypes.FETCH_DASHBOARD_WORKSPACES](
    { commit }: DashboardActionContext,
    payload: {
      name: string;
      loadMore: boolean;
      status?: string;
    }
  ): Promise<DashboardWorkspace[]>;
  [ActionTypes.LOAD_MORE_FAVOURITE_WORKSPACES]({
    commit
  }: DashboardActionContext): Promise<DashboardWorkspace[]>;
}

export enum MutationTypes {
  SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA",
  RESET_DASHBOARD_DATA = "RESET_DASHBOARD_DATA",
  SET_PAGINATED_WORKSPACES = "SET_PAGINATED_WORKSPACES",
  SET_PAGINATED_FAVOURITE_WORKSPACES = "SET_PAGINATED_FAVOURITE_WORKSPACES"
}

type DashboardMutations = {
  [MutationTypes.SET_DASHBOARD_DATA](
    state: DashboardState,
    payload: DashboardQueryReturnType
  ): void;
  [MutationTypes.RESET_DASHBOARD_DATA](state: DashboardState): void;
  [MutationTypes.SET_PAGINATED_WORKSPACES](
    state: DashboardState,
    payload: Omit<Paginated<DashboardWorkspace>, "step">
  ): void;
  [MutationTypes.SET_PAGINATED_FAVOURITE_WORKSPACES](
    state: DashboardState,
    payload: Omit<Paginated<DashboardWorkspace>, "step">
  ): void;
};

/**********************
 *       State        *
 **********************/
const state: DashboardState = {
  informations: null,
  billing: null,
  favoriteWorkspaces: {
    list: null,
    hasNextPage: false,
    endCursor: ""
  },
  users: {
    list: null,
    totalCount: 0
  },
  workspaces: {
    list: null,
    hasNextPage: false,
    endCursor: ""
  }
};

/**********************
 *      Getters       *
 **********************/
const getters = {};

/**********************
 *      Actions       *
 **********************/
const actions: ActionTree<DashboardState, RootState> & DashboardActions = {
  async [ActionTypes.FETCH_DASHBOARD_DATA]({ commit, rootState }) {
    const orgId = rootState.organizationId;
    if (!orgId) {
      throw new Error(
        "FETCH_DASHBOARD_DATA error: No organization id registered in state."
      );
    }
    const now = new Date();

    const { data } = await apolloWrapper.query<{
      organizationContext: DashboardQueryReturnType;
    }>(
      {
        query: GET_DASHBOARD_DATA,
        variables: {
          currentMonth: now.getMonth() + 1,
          currentYear: now.getFullYear()
        },
        fetchPolicy: "network-only"
      },
      false
    );
    commit(MutationTypes.SET_DASHBOARD_DATA, data.organizationContext);
    return data.organizationContext;
  },

  async [ActionTypes.FETCH_DASHBOARD_WORKSPACES](
    { commit, rootState, state },
    { name, loadMore, status }
  ) {
    const orgId = rootState.organizationId;
    if (!orgId) {
      throw new Error(
        "FETCH_DASHBOARD_WORKSPACES error: No organization id registered in state."
      );
    }

    const cursor = loadMore ? state.workspaces.endCursor : "";

    const { data } = await apolloWrapper.query({
      query: GET_DASHBOARD_WORKSPACES,
      fetchPolicy: "network-only",
      variables: { cursor, name, status: status ? status : "ACTIVE" }
    });

    const list = data.organizationContext.workspaces.edges.map(
      n => n.node
    ) as DashboardWorkspace[];
    commit(MutationTypes.SET_PAGINATED_WORKSPACES, {
      list: loadMore ? [...(state.workspaces.list || []), ...list] : list,
      hasNextPage: data.organizationContext.workspaces.pageInfo.hasNextPage,
      endCursor: data.organizationContext.workspaces.pageInfo.endCursor || ""
    });
    return list;
  },

  async [ActionTypes.LOAD_MORE_FAVOURITE_WORKSPACES]({
    commit,
    rootState,
    state
  }) {
    const orgId = rootState.organizationId;
    if (!orgId) {
      throw new Error(
        "LOAD_MORE_FAVOURITE_WORKSPACES error: No organization id registered in state."
      );
    }

    const { data } = await apolloWrapper.query<{
      organizationContext: DashboardQueryReturnType;
    }>({
      query: GET_DASHBOARD_FAVOURITE_WORKSPACES,
      fetchPolicy: "network-only",
      variables: { cursor: state.favoriteWorkspaces.endCursor }
    });
    const list = data.organizationContext.favoriteWorkspaces.edges.map(
      n => n.node
    ) as DashboardWorkspace[];

    commit(MutationTypes.SET_PAGINATED_FAVOURITE_WORKSPACES, {
      list: [...(state.favoriteWorkspaces.list || []), ...list],
      hasNextPage:
        data.organizationContext.favoriteWorkspaces.pageInfo.hasNextPage,
      endCursor: data.organizationContext.favoriteWorkspaces.pageInfo.endCursor
    });
    return list;
  }
};

/**********************
 *     Mutations      *
 **********************/
const mutations: MutationTree<DashboardState> & DashboardMutations = {
  [MutationTypes.SET_DASHBOARD_DATA](state, data) {
    const { informations, billing, favoriteWorkspaces, users, ws } = data;

    state.informations = informations;
    state.billing = billing[0]
      ? {
          month: billing[0].month,
          year: billing[0].year,
          currency: billing[0].currency,
          totalAmount: billing.reduce((acc, val) => acc + val.amount, 0) / 100, // Stripe sends back cents
          totalCredits: billing.reduce((acc, val) => acc + val.credits, 0)
        }
      : null;
    state.favoriteWorkspaces = {
      ...state.favoriteWorkspaces,
      list: favoriteWorkspaces.edges.map(n => n.node),
      hasNextPage: favoriteWorkspaces.pageInfo.hasNextPage,
      endCursor: favoriteWorkspaces.pageInfo.endCursor
    };
    state.users = {
      list: users.edges.map(n => n.node),
      totalCount: users.totalCount
    };
    state.workspaces = {
      ...state.workspaces,
      list: ws.edges.map(n => n.node),
      hasNextPage: ws.pageInfo.hasNextPage,
      endCursor: ws.pageInfo.endCursor
    };
  },
  [MutationTypes.RESET_DASHBOARD_DATA](state) {
    state.informations = null;
    state.billing = null;
    state.favoriteWorkspaces = {
      list: null,
      hasNextPage: false,
      endCursor: ""
    };
    state.users = {
      list: null,
      totalCount: 0
    };
    state.workspaces = {
      list: null,
      hasNextPage: false,
      endCursor: ""
    };
  },
  [MutationTypes.SET_PAGINATED_WORKSPACES](state, page) {
    state.workspaces = {
      ...page
    };
  },
  [MutationTypes.SET_PAGINATED_FAVOURITE_WORKSPACES](state, page) {
    state.favoriteWorkspaces = {
      ...page
    };
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
